<template>
  <Gallery />
</template>

<script>
import Gallery from "@/components/Gallery.vue";
export default {
  name: "WrapperGallery",
  components: {
    Gallery,
  },
};
</script>
